import { Template } from './../../Stampe/Models/Template';
import { BehaviorSubject } from 'rxjs';
import { ApiRestService } from './api-rest.service';
import { Injectable } from '@angular/core';
import { Files } from '../models/File';
import { ApiPathsService } from 'src/app/api-paths.service';

@Injectable({
  providedIn: 'root'
})
export class FileStoreService {

  constructor(
    private apiRestService: ApiRestService,
    private apiPaths: ApiPathsService,
  ) { }
  private LISTA_FILE = new BehaviorSubject<Files[]>([]);
  private FILE = new BehaviorSubject<Files>(null);
  private SEZIONE = new BehaviorSubject<string>(null);
  private dataStore: {
    ListaFile: Files[],
    File: Files,
    Sezione: string,
  } = {
      ListaFile: [],
      File: null,
      Sezione: null,
    }
  readonly $listaFile = this.LISTA_FILE.asObservable();
  readonly $file = this.FILE.asObservable();
  readonly $Sezione = this.SEZIONE.asObservable();
  refreshListaFile = () => this.LISTA_FILE.next(Object.assign({}, this.dataStore).ListaFile);
  refreshFile = () => this.FILE.next(Object.assign({}, this.dataStore).File);
  refreshSezione = () => this.SEZIONE.next(Object.assign({}, this.dataStore).Sezione);
  SetListaFile(data) {
      this.dataStore.ListaFile = data;
      this.refreshListaFile();
  }
  updateFile(file: Files) {
    return this.apiRestService.updateFile(file)
  }
  updateSelectedFile(file: Files) {
    this.dataStore.File = file;
    this.refreshFile();
  }
  SelectSection(sezione: string) {
    this.dataStore.Sezione = sezione;
    this.refreshSezione();
  }

  deleteFile(file: Files | Template) {
    return this.apiRestService.deleteFile(file);
  }

}
