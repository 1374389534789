import {Component, AfterViewInit, EventEmitter, Output, Input} from '@angular/core';
import {
  NgbModal,
  ModalDismissReasons,
  NgbPanelChangeEvent,
  NgbCarouselConfig
} from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { TranslateService } from '@ngx-translate/core';
import {GlobalStoreService} from "../../shared/_services/global-store.service";
declare var $: any;

@Component({
  selector: 'app-vertical-navigation',
  templateUrl: './vertical-navigation.component.html'
})
export class VerticalNavigationComponent implements AfterViewInit {
  @Output() toggleSidebar = new EventEmitter<void>();
  @Input() customizer: boolean = false;
  @Output() customizerChange = new EventEmitter<boolean>();
  public config: PerfectScrollbarConfigInterface = {};
  public selectedLanguage: any = {
    language: 'Italian',
    code: 'it',
    type: 'IT',
    icon: 'it'
  }

  public languages: any[] = [
    {
      language: 'English',
      code: 'en',
      type: 'US',
      icon: 'us'
    },
    {
      language: 'Español',
      code: 'es',
      icon: 'es'
    },
    {
      language: 'Français',
      code: 'fr',
      icon: 'fr'
    },
    {
      language: 'German',
      code: 'de',
      icon: 'de'
    },
    {
      language: 'Italian',
      code: 'it',
      icon: 'it'
    }
  ]
  notifiche: number;



  constructor(private modalService: NgbModal, private translate: TranslateService, public store: GlobalStoreService) {
    translate.setDefaultLang('it');
    store.$numNotifiche.subscribe(data => this.notifiche = data)
  }

  changeLanguage(lang: any) {
    this.translate.use(lang.code)
    this.selectedLanguage = lang;
  }

  ngAfterViewInit() { }
}
