import {Injectable} from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpHeaderResponse,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import {NgxSpinnerService} from 'ngx-spinner';
import {Observable} from 'rxjs';
import {finalize, tap} from 'rxjs/operators';
import {ToastrService} from "ngx-toastr";

@Injectable()
export class SpinnerInterceptor implements HttpInterceptor {
  count = 0;

  constructor(private spinner: NgxSpinnerService, private toastService: ToastrService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.spinner.show();
    this.count++;

    return next.handle(request)
      .pipe(
        tap(
           event => {
             if(event instanceof HttpResponse && event?.ok && event?.body?.success == false ){
               let errorMessage = "";
               if(event?.body?.messages)
                 for (const field in event?.body?.messages)
                   errorMessage += field + " : " + event?.body?.messages[field] + '\n';
               else if(event?.body?.message)
                 errorMessage = event?.body?.message;
               this.toastService.error(errorMessage, 'Oops!');
               console.log(event)
             }

           },
          // error => console.log(error)
        ), finalize(() => {
          this.count--;
          if ( this.count === 0 ) { this.spinner.hide(); }
        })
      );
  }
}
