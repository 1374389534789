import { RouteInfo } from "../layouts/horizontal-sidebar/horizontal-sidebar.metadata";

export const HORIZONTALROUTESADMIN: RouteInfo[] = [
  {
    path: "",
    title: "Menu",
    icon: "",
    class: "",
    ddclass:"",
    extralink: true,
    submenu: []
  },
  {
    path: "",
    title: "Admin",
    icon: "home",
    class: "has-arrow",
    ddclass:"",
    extralink: false,
    submenu: [
        {
          path: "/listautenti/personale",
          title: "Lista utenti",
          icon: "mdi mdi-adjust",
          class: "",
          ddclass:"",
          extralink: false,
          submenu: [],
        }
    ],
  }
];
