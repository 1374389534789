import { RouteInfo } from '../../layouts/horizontal-sidebar/horizontal-sidebar.metadata';
import { LoggedUser } from '../Models/LoggedUser';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { retry, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import {Azienda} from "../../Admin/models/Azienda";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<LoggedUser>;
  public currentUser: Observable<LoggedUser>;
  private isLogoutEnabled: boolean;
  constructor(
    private http: HttpClient,
    private router: Router,
  ) {
    this.currentUserSubject = new BehaviorSubject<LoggedUser>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
    this.isLogoutEnabled = true;
  }
  public get currentUserValue(): LoggedUser {
    return this.currentUserSubject.value;
  }
login(username: string, password: string) : Observable<any>{
    return this.http.post<any>(environment.apiUrl + (environment.Wp ? '/jwt-auth/v1/token' : '/auth/login'), { username, password, deviceid: "default" })
      .pipe(map(user => {
        user.authdata = window.btoa(username + ':' + password);
        user.username = username;
        user.access_token = user.access_token? user.access_token : user.token;
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.currentUserSubject.next(user);
        console.log("UTENTE LOGGATO: ", user);
        return user;
      }));
  }
  signUp(val:Azienda): Observable<any> {
    return this.http.post<any>(environment.apiUrl + (environment.Wp ? '/eventup/client/register' : '/auth/register'),
      JSON.stringify(val),{
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      }
    ).pipe(map(user => {}));
  }
  verify(val): Observable<any> {
    return this.http.post<any>(environment.apiUrl + '/auth/check',
      JSON.stringify(val),{
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.currentUserValue.access_token}`
        }),
      }
    ).pipe(map(user => {}));
  }
  method(val): Observable<any> {
    return this.http.post<any>(environment.apiUrl + '/auth/check/method',
      JSON.stringify(val),{
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.currentUserValue.access_token}`
        }),
      }
    ).pipe(map(user => {}));
  }

  logout() {
    if(!this.currentUserValue?.access_token ) return;
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
    this.router.navigate(["/Login"]);
    return;
  }

  public get logoutEnabled(): boolean {
    return this.isLogoutEnabled;
  }

  public set logoutEnabled(val: boolean) {
    this.isLogoutEnabled = val;
  }

  public get username(): string {
    return this.currentUserValue.username;
  }
  isAmministrazione() {
    return this.currentUserValue && this.currentUserValue.ruoliObject.find(x => x.id === "A") !== undefined;
  };
  recoverpwd(email): Observable<any> {
    return this.http.post<any>(environment.apiUrl + (environment.Wp ? '/eventup/resetpwd' : '/auth/recover'), JSON.stringify({email: email}), {headers: new HttpHeaders({'Content-Type': 'application/json',}),})
      .pipe(map(data => {
        return data;
      }));
  }
  resetpwd(value): Observable<any> {
    return this.http.post<any>(environment.apiUrl + '/auth/reset', JSON.stringify(value),{headers: new HttpHeaders({'Content-Type': 'application/json',}),})
      .pipe(map(data => {
        return data;
      }));
  }

  updateuser(user){ this.currentUserSubject.next(user);}


}
