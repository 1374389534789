import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiPathsService } from './../../api-paths.service';
import { AuthenticationService } from './../../Login/_services/authentication.service';
import { Observable } from 'rxjs';
import { Files } from '../models/File';
import {Dashboard} from "../models/dashboard";

@Injectable({
  providedIn: 'root'
})
export class ApiRestService {
  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    private apiPaths: ApiPathsService,
  ) { }
  headerforfile() {
    return new HttpHeaders({
      // 'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${this.authenticationService.currentUserValue.access_token}`,
    });
  }
  header() {
    return new HttpHeaders({
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${this.authenticationService.currentUserValue.access_token}`,
    });
  }
  /**
   * Opzioni di default per le richieste HTTP
   */
  options() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.authenticationService.currentUserValue.access_token}`,
      }),
    };
  }
  //#region file
  updateFile(parhams): Observable<any> {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/file/update`,
      JSON.stringify(parhams),
      this.options()).pipe();
  }
  deleteFile(parhams): Observable<any> {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/file/delete`,
      JSON.stringify(parhams),
      this.options()).pipe();
  }
  downloadFle(id: number): Observable<any> {
    return this.http.get(`${this.apiPaths.getBaseAPI()}/file/download/${id.toString()}`,
      { headers: this.header(),observe:'response',responseType:'blob'}).pipe();
  }
  //#endregion
  GetDashboardData(parhams): Observable<Dashboard> {
    return this.http.post<Dashboard>(`${this.apiPaths.getBaseAPI()}/progetti/progetti/dashboard`,
      JSON.stringify(parhams),
      this.options()
    ).pipe(map((data) => {
      return data;
    }));
  }
  GetNotification(): Observable<number> {
    return this.http.get<any>(`${this.apiPaths.getBaseAPI()}/scadenzario/scadenze/alert`,
      this.options()
    ).pipe(map((data) => {
      return data.data;
    }));
  }
}
