import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthenticationService } from '../../Login/_services/authentication.service';
import {TranslateService} from "@ngx-translate/core";

@Injectable()
export class BasicAuthInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService, private translate: TranslateService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      request = request.clone({
        setHeaders: {
          Authorization: this.authenticationService?.currentUserValue?.access_token ? `Bearer ${this.authenticationService.currentUserValue.access_token}`: "",
          'Accept-Language':  this.translate.currentLang ?? 'it'
        }
      });
      return next.handle(request);
    }
}
