import { environment } from 'src/environments/environment';
import { PersonaleStoreService } from '../../../Admin/_service/personale-store.service';
import { LoggedUser } from '../../Models/LoggedUser';
import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../_services/authentication.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FileStoreService } from 'src/app/shared/_services/file-store.service';
import {Permission} from "../../_guards/Permission";

@Component({
  selector: 'app-user-dropdown',
  templateUrl: './user-dropdown.component.html'
})
export class UserDropdownComponent implements OnInit {
  environment = environment;
  currentUser: LoggedUser
  bgcolor = environment.userCircleColor;
  constructor(
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router,
    private filestore: FileStoreService,
    private personaleStore: PersonaleStoreService,
    public permission: Permission,
  ) {
     this.currentUser = this.authenticationService.currentUserValue;
   }

  ngOnInit() {
  }
  LogOut() {
    this.authenticationService.logout();
  }
  Profilo() {
    if(this.permission.isOperativeRoles(environment.ClientiType)) {
      this.router.navigate([this.route.snapshot.queryParams['Cliente/profile'] || 'Cliente/profile',])
      return;
    }
    this.personaleStore.updateEditable(true);
    this.personaleStore.updateShowAddForm(false);
    this.personaleStore.updateSelectedUserWhenProfile(this.currentUser);
    this.filestore.SelectSection('Profilo');
    this.router.navigate([this.route.snapshot.queryParams[' listautenti/profilo '] ||'listautenti/profilo',])
  }

}
