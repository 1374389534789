import { Injectable } from "@angular/core";
import { AuthenticationService } from "../_services/authentication.service";

@Injectable({ providedIn: 'root' })
export class Permission {
  constructor(private  authenticationService: AuthenticationService){
  }
  isPermitted(capacity: string){
    return (this.isOperativeRole("SA") || this.authenticationService?.currentUserValue?.capacita?.findIndex(x => x === capacity) >- 1)
  }
  HasCapacity(capacity: string[]) {
    return !(capacity?.length > 0) || capacity?.find(cap => this.isPermitted(cap)) != undefined;
  }
  HasnotCapacity(capacity: string[]) {
    return capacity?.find(cap => this.authenticationService?.currentUserValue?.capacita?.findIndex(x => x === cap) >- 1) == undefined;
  }
  hasOperativeRole(role: string){
    return this.isOperativeRoles(["SA", role]);
  }
  hasOperativeRoles(roles: string[]){
    return this.isOperativeRoles(["SA"].concat(roles));
  }
  isOperativeRole(role: string){
    return this.authenticationService?.currentUserValue?.ruoliObject?.find(x=> x.id?.toUpperCase() === role?.toUpperCase()) != undefined
  }
  isOperativeRoles(roles: string[]){
    return !(roles?.length > 0) || roles?.find(role => this.isOperativeRole(role) ) != undefined
  }
  isntOperativeRole(role: string){
    return this.authenticationService?.currentUserValue?.ruoliObject?.find(x=> x.id?.toUpperCase() === role?.toUpperCase()) == undefined
  }
  isntOperativeRoles(roles: string[]){
    return !(roles?.length > 0) || roles?.find(role => this.isOperativeRole(role)) == undefined
  }
  itsme_mario(id: number){
    return this.authenticationService?.currentUserValue?.id === id;
  }
  IAmYourFather(){
    return this.isOperativeRole("SA");
  }
}
