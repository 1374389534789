import {AuthenticationService} from "../../Login/_services/authentication.service";
import {ToastrService} from "ngx-toastr";
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Observable} from "rxjs";
import {finalize, tap} from "rxjs/operators";
import {ApiRestService} from "../_services/api-rest.service";
import {Injectable} from "@angular/core";
import {GlobalStoreService} from "../_services/global-store.service";
import {Permission} from "../../Login/_guards/Permission";

@Injectable()
export class SuccessInterceptor implements HttpInterceptor {
  constructor (
    private authenticationService: AuthenticationService,
    private toastService: ToastrService,
    private GlobalStore: GlobalStoreService,
    private permission: Permission
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // if(!request.url.includes('scadenzario/scadenze/alert'))
    //   this.GlobalStore.updateNotifiche();
    return next.handle(request).pipe(tap(), finalize(() => {}));
  }
}
