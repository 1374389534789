import { Injectable } from '@angular/core';
import {ApiRestService} from "./api-rest.service";
import {ApiPathsService} from "../../api-paths.service";
import {BehaviorSubject} from "rxjs";
import {Files} from "../models/File";
import {Template} from "../../Stampe/Models/Template";
import {Permission} from "../../Login/_guards/Permission";

@Injectable({
  providedIn: 'root'
})
export class GlobalStoreService {
  constructor(private apiRestService: ApiRestService,
              private apiPaths: ApiPathsService,
              private permission: Permission
  ) { }
  private NUM_NOTIFICHE = new BehaviorSubject<number>(undefined);
  private dataStore: { Num_Notifiche: number, } = {Num_Notifiche: undefined}
  readonly $numNotifiche = this.NUM_NOTIFICHE.asObservable();
  refreshNum_Notifiche = () => this.NUM_NOTIFICHE.next(Object.assign({}, this.dataStore).Num_Notifiche);
  updateNotifiche() {
    this.apiRestService.GetNotification().subscribe((data)=>{
      if(this.permission.isPermitted('menu-scadenzario'))
        this.dataStore.Num_Notifiche = data;
      else
        this.dataStore.Num_Notifiche = 0;
      this.refreshNum_Notifiche();
    })
  }
}
