import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RouteInfo } from './vertical-sidebar.metadata';
import { VerticalSidebarService } from './vertical-sidebar.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {Permission} from "../../Login/_guards/Permission";
import {environment} from "../../../environments/environment";
declare var $: any;

@Component({
  selector: 'app-vertical-sidebar',
  templateUrl: './vertical-sidebar.component.html'
})
export class VerticalSidebarComponent {
  showMenu = '';
  showSubMenu = '';
  public sidebarnavItems: RouteInfo[] = [];
  path = '';
  @Input() showClass: boolean = false;
  @Output() notify: EventEmitter<boolean> = new EventEmitter<boolean>();
  handleNotify() {
    this.notify.emit(!this.showClass);
  }

  constructor(private menuServise: VerticalSidebarService, private router: Router, private permission: Permission) {
    this.menuServise?.items?.subscribe(menuItems => {
      this.sidebarnavItems = menuItems?.filter(x=>
        (x?.enabled == undefined || x?.enabled == true) &&
        this.permission.HasCapacity(x?.capacita) &&
        this.permission.isOperativeRoles(x?.ruoliOperativi) &&
        this.permission.isntOperativeRoles(x?.notruoliOperativi) &&
        (x.module == undefined || environment.Modules[x.module])
      );
      this.sidebarnavItems?.filter(m => m?.submenu?.filter(s => s?.path === this.router?.url ? this.path = m?.title : this.path));
      this.addExpandClass(this.path);
    });
  }

  addExpandClass(element: any) {
    this.showMenu = element === this.showMenu ? '0' : element;
  }

  addActiveClass(element: any) {
    this.showSubMenu = element === this.showSubMenu? '0' : element;
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }
}
