import {environment} from '../../../environments/environment';
import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {RouteInfo} from './vertical-sidebar.metadata';
import {Permission} from "../../Login/_guards/Permission";
import {AuthenticationService} from "../../Login/_services/authentication.service";


@Injectable({
    providedIn: 'root'
})
export class VerticalSidebarService {
  constructor(private  permission: Permission, private authenticationService: AuthenticationService) {
    this.authenticationService.currentUser.subscribe(data=>{
      this.MENUITEMS = environment.Menu;
      this.items = new BehaviorSubject<RouteInfo[]>(this.MENUITEMS);
    })
  }
  public screenWidth: any;
  public collapseSidebar: boolean = false;
  public fullScreen: boolean = false;

  MENUITEMS: RouteInfo[] = environment.Menu;
  items = new BehaviorSubject<RouteInfo[]>(this.MENUITEMS);

}
