<nav class="sidebar-nav">
    <ul id="sidebarnav">
        <!-- First level menu -->
        <li class="sidebar-item" [class.active]="showMenu === sidebarnavItem?.title"
            *ngFor="let sidebarnavItem of sidebarnavItems"
            (click)="sidebarnavItem?.submenu?.length != 0 ? '' : handleNotify()"
            [routerLinkActive]="sidebarnavItem?.submenu?.length != 0 ? '' : 'active'">
            <div class="nav-small-cap" *ngIf="sidebarnavItem?.extralink === true"><i
                    [ngClass]="[sidebarnavItem?.icon??'']"></i><span
                    class="hide-menu">{{sidebarnavItem.title | translate}}</span></div>
            <a class="sidebar-link waves-effect waves-dark"
                [routerLink]="sidebarnavItem?.class === '' || sidebarnavItem?.class === undefined ? [sidebarnavItem?.path] : null"
                [ngClass]="[sidebarnavItem.class ?? '']" *ngIf="!sidebarnavItem.extralink;"
                (click)="addExpandClass(sidebarnavItem.title)"
                [routerLinkActive]="sidebarnavItem?.submenu?.length != 0 ? '' : 'active'">
                 <i [ngClass]="[sidebarnavItem?.icon ?? '']"></i>
<!--                <i-feather [name]="sidebarnavItem.icon" [ngClass]="[sidebarnavItem.icon]"></i-feather>-->
                <span class="hide-menu">{{sidebarnavItem?.title | translate}}
                    <span *ngIf="sidebarnavItem?.label != '' " [ngClass]="[sidebarnavItem?.labelClass ?? '']">{{sidebarnavItem?.label | translate}}</span>
                </span>
            </a>
            <!-- Second level menu -->
            <ul aria-expanded="false" class="collapse first-level" *ngIf="sidebarnavItem?.submenu?.length > 0"
                [ngClass]="{'in' : showMenu === sidebarnavItem.title }">
                <li class="sidebar-item" *ngFor="let sidebarnavSubItem of sidebarnavItem?.submenu"
                (click)="sidebarnavSubItem?.submenu?.length != 0 ? '' : handleNotify()"
                    [class.active]="showSubMenu === sidebarnavSubItem?.title"
                    [routerLinkActive]="sidebarnavSubItem?.submenu?.length > 0 ? '' : 'active'">
                    <a class="sidebar-link"
                        [routerLink]="sidebarnavSubItem?.submenu?.length > 0 ? null : [sidebarnavSubItem?.path]"
                        [routerLinkActive]="sidebarnavSubItem?.submenu?.length > 0 ? '' : 'router-link-active'"
                        [ngClass]="[sidebarnavSubItem?.class ?? '']" *ngIf="!sidebarnavSubItem?.extralink;"
                        (click)="addActiveClass(sidebarnavSubItem?.title ?? '')">
                        <i [ngClass]="[sidebarnavSubItem.icon?? '']"></i>
                        <span class="hide-menu">{{sidebarnavSubItem.title ?? '' | translate}}</span>
                    </a>
                    <!-- Third level menu -->
                    <ul aria-expanded="false" class="collapse Second-level" *ngIf="sidebarnavSubItem?.submenu?.length > 0"
                        [ngClass]="{'in' : showSubMenu === sidebarnavSubItem?.title }">
                        <li class="sidebar-item" *ngFor="let sidebarnavSubsubItem of sidebarnavSubItem?.submenu"
                            [routerLinkActive]="sidebarnavSubsubItem?.submenu?.length > 0 ? '' : 'active'" [ngClass]="[sidebarnavSubsubItem?.class]">
                            <a class="sidebar-link" [routerLink]="[sidebarnavSubsubItem?.path]"
                                *ngIf="!sidebarnavSubsubItem?.extralink;"
                                [routerLinkActive]="sidebarnavSubsubItem?.submenu?.length > 0 ? '' : 'router-link-active'">
                                <i [ngClass]="[sidebarnavSubsubItem?.icon??'']"></i>
                                <span class="hide-menu">{{sidebarnavSubsubItem?.title | translate}}</span>
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
        </li>
    </ul>
</nav>
